import { render, staticRenderFns } from "./UserActions.vue?vue&type=template&id=b1d58472&"
import script from "./UserActions.vue?vue&type=script&lang=js&"
export * from "./UserActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports